import { HttpErrorResponse } from "@angular/common/http";
import { Action, createFeatureSelector, createSelector } from "@ngrx/store";
import * as _ from "lodash";

import {
  IDDQDocument,
  IDDQGroup,
  IDDQSection,
  IGroupReview,
  ISectionReviewsModel,
  IApproveModel,
  IDiffRatingSection,
  IDiffRatingGroup,
  ResidualRiskCategory,
  IAutosaveSectionData,
  IAutosaveInternalSectionData,
  IAutosaveApprovalData,
  IRiskModelData,
  IAmendRatingsModel
} from "@shared/models/ddq-document.interfaces";
import { Question, IAuditTrail } from "@shared/models/questionnaire.interfaces";

/**
 * FM review action types
 */
export enum FMReviewActionTypes {
  DDQ_GET_REVIEW_DATA = "[FMReview] Get",
  DDQ_GET_REVIEW_DATA_SUCCESS = "[FMReview] Get Success",
  DDQ_GET_REVIEW_DATA_ERROR = "[FMReview] Get Error",
  DDQ_REVIEW_GET_GROUP = "[FMReview] Get Group",
  DDQ_REVIEW_GET_GROUP_SUCCESS = "[FMReview] Get Group Success",
  DDQ_REVIEW_GET_GROUP_ERROR = "[FMReview] Get Group Error",
  DDQ_REVIEW_SAVE_SECTION = "[FMReview] Save Section",
  DDQ_REVIEW_SAVE_INTERNAL_SECTION = "[FMReview] Save Internal Section",
  DDQ_REVIEW_SUBMIT_SECTION = "[FMReview] Submit Section",
  DDQ_REVIEW_SUBMIT_INTERNAL_SECTION = "[FMReview] Submit Internal Section",
  DDQ_REVIEW_UPDATE_FILTER_MESSAGE = "[FMReview] Update Filter Message",
  DDQ_REVIEW_UPDATE_SECTION_RATING = "[FMReview] Update Section Rating",
  DDQ_REVIEW_UPDATE_GROUP_RATING = "[FMReview] Update Group Rating",
  DDQ_REVIEW_UPDATE_QUESTION_RATING = "[FMReview] Update Question Rating",
  DDQ_REVIEW_UPDATE_QUESTION_CLARIFICATIONS_AMOUNT = "[FMReview] Update Question Clarifications Amount",
  DDQ_REVIEW_UPDATE_OVERALL_RATING = "[FMReview] Update Overall Rating",
  DDQ_REVIEW_UPDATE_RESIDUAL_RISK_CATEGORY = "[FMReview] Update Residual Risk Category",
  DDQ_REVIEW_UPDATE_GROUP_COMMENT = "[FMReview] Update Group Comment",
  DDQ_REVIEW_UPDATE_REVIEWER_SECTION_COMMENT = "[FMReview] Update Reviewer Section Comment",
  DDQ_REVIEW_UPDATE_OVERALL_COMMENT = "[FMReview] Update Overall Comment",
  DDQ_REVIEW_EXECUTIVE_OVERVIEW = "[FMReview] Update Executive Overview",
  DDQ_REVIEW_SECTION_CLEAR_DIRTY = "[FMReview] Section Clear Dirty",
  DDQ_REVIEW_GROUP_CLEAR_DIRTY = "[FMReview] Group Clear Dirty",
  DDQ_REVIEW_SECTION_UPDATE_STATUS = "[FMReview] Section Update Status",
  DDQ_REVIEW_DOCUMENT_UPDATE_STATUS = "[FMReview] Document Update Status",
  DDQ_REVIEW_UPDATE_SECTION_APPROVER_DOCUMENTS = "[FMReview] Update Section Approver Documents",
  DDQ_REVIEW_UPDATE_SECTION_DOCUMENTS = "[FMReview] Update Section Documents",
  DDQ_REVIEW_UPDATE_GROUP_DOCUMENTS = "[FMReview] Update Group Documents",
  DDQ_REVIEW_UPDATE_QUESTION_ANSWER = "[FMReview] Update Question Answer",
  DDQ_REVIEW_UPDATE_QUESTION_DOCUMENTS = "[FMReview] Update Question Documents",
  DDQ_REVIEW_APPROVE_SECTION = "[FMReview] Approve Section",
  DDQ_REVIEW_APPROVE_SECTION_SUCCESS = "[FMReview] Approve Section Success",
  DDQ_REVIEW_REJECT_SECTION = "[FMReview] Reject Section",
  DDQ_REVIEW_REJECT_SECTION_SUCCESS = "[FMReview] Reject Section Success",
  DDQ_REVIEW_APPROVE_DOCUMENT = "[FMReview] Approve Document",
  DDQ_REVIEW_SAVE_DOCUMENT = "[FMReview] Save Document",
  DDQ_REVIEW_SAVE_DOCUMENT_SUCCESS = "[FMReview] Save Document Success",
  DDQ_REVIEW_SAVE_DOCUMENT_ERROR = "[FMReview] Save Document Error",
  DDQ_REVIEW_SUBMIT_ALL_SECTIONS = "[FMReview] Submit Sections",
  DDQ_REVIEW_SUBMIT_ALL_SECTIONS_SUCCESS = "[FMReview] Submit Sections Success",
  DDQ_REVIEW_SUBMIT_ALL_SECTIONS_ERROR = "[FMReview] Submit Sections Error",
  DDQ_REVIEW_APPROVE_ALL_SECTIONS = "[FMReview] Approve Sections",
  DDQ_REVIEW_APPROVE_ALL_SECTIONS_SUCCESS = "[FMReview] Approve Sections Success",
  DDQ_REVIEW_APPROVE_ALL_SECTIONS_ERROR = "[FMReview] Approve Sections Error",
  DDQ_REVIEW_UPDATE_OVERALL_DOCUMENTS = "[FMReview] Update Overall Documents",
  DDQ_REVIEW_UPDATE_EXECUTIVE_OVERVIEW = "[FMReview] Update Executive Overview",
  DDQ_REVIEW_RECALL = "[FMReview] Recall",
  DDQ_REVIEW_RECALL_APPROVED_SECTION = "[FMReview] Recall Approved Section",
  DDQ_REVIEW_EXPORT_PDF = "[FMReview] Export PDF",
  DDQ_REVIEW_EXPORT_PDF_SUCCESS = "[FMReview] Export PDF Success",
  DDQ_REVIEW_EXPORT_PDF_ERROR = "[FMReview] Export PDF Error",
  DDQ_REVIEW_GET_HISTORY_ACTIONS = "[FMReview] Get History Actions",
  DDQ_REVIEW_UPDATE_HISTORY_ACTIONS = "[FMReview] Update History Actions",
  DDQ_UPDATE_SUM_QUESTIONS_RATINGS = "[FMReview] Update Sum Questions Ratings",
  DASHBOARD_ACTIONS_ARCHIVE = "[FMReview] Dashboard Actions Archive",
  DASHBOARD_ACTIONS_ARCHIVE_ERROR = "[FMReview] Dashboard Actions Archive Error",
  DASHBOARD_ACTIONS_ARCHIVE_SUCCESS = "[FMReview] Dashboard Actions Archive Success",
  DDQ_REVIEW_UPDATE_HAS_DIFFERENTLY_RATING_PROPERTY = "[FMReview] Update Has Differently Rating Property",
  DDQ_REVIEW_OVERALL_GET_HISTORY_ACTIONS = "[FMReview] DDQ Review Overall Get History",
  DDQ_REVIEW_OVERALL_UPDATE_HISTORY_ACTIONS = "[FMReview] DDQ Review Overall Update History",
  DDQ_REVIEW_UPDATE_SECTION_CLARIFICATIONS_IN_DRAFT_AMOUNT = "[FMReview] Update Section Clarifications In Draft Amount",
  SET_AUTOSAVE_SECTION_DATA = "[FMReview] Set Autosave Section Data",
  SEND_AUTOSAVE_SECTION_DATA = "[FMReview] Send Autosave Section Data",
  SET_AUTOSAVE_INTERNAL_SECTION_DATA = "[FMReview] Set Autosave Internal Section Data",
  SEND_AUTOSAVE_INTERNAL_SECTION_DATA = "[FMReview] Send Autosave Internal Section Data",
  SET_AUTOSAVE_APPROVAL_DATA = "[FMReview] Set Autosave Approval Data",
  SEND_AUTOSAVE_APPROVAL_DATA = "[FMReview] Send Autosave Approval Data",
  DDQ_REVIEW_VALIDATE_SECTION = "[FMReview] Validate Section",
  DDQ_REVIEW_SECTION_UPDATE_SUBMIT_PROCESS_STATUS = "[FMReview] Section Update Submit Process Status",
  DDQ_REVIEW_GET_OVERALL_RISK_PRIORITY = "[FMReview] Get Overall Risk Priority",
  DDQ_REVIEW_GET_OVERALL_RISK_PRIORITY_SUCCESS = "[FMReview] Get Overall Risk Priority Success",
  DDQ_REVIEW_GET_OVERALL_RISK_PRIORITY_ERROR = "[FMReview] Get Overall Risk Priority Error",
  DDQ_REVIEW_GET_RISK_MODEL_DATA = "[FMReview] Get Risk Model Data",
  DDQ_REVIEW_GET_RISK_MODEL_DATA_SUCCESS = "[FMReview] Get Risk Model Data Success",
  DDQ_REVIEW_AMEND_RISK_MODEL_DATA = "[FMReview] Amend Risk Model Data",
  DDQ_REVIEW_AMEND_RISK_MODEL_DATA_SUCCESS = "[FMReview] Amend Risk Model Data Success",
  DDQ_REVIEW_SET_SCHEDULE_REVIEW = "[FMReview] Set Schedule Review"
}

export class DDQReviewDataGet implements Action {
  readonly type = FMReviewActionTypes.DDQ_GET_REVIEW_DATA;
  constructor(public payload: { distributorId: string; documentId: string }) {}
}

export class DDQReviewDataGetSuccess implements Action {
  readonly type = FMReviewActionTypes.DDQ_GET_REVIEW_DATA_SUCCESS;
  constructor(public payload: { ddqReviewData: IDDQDocument; documentId: string }) {}
}

export class DDQReviewDataGetError implements Action {
  readonly type = FMReviewActionTypes.DDQ_GET_REVIEW_DATA_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class DDQReviewGroupGet implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_GET_GROUP;
  public constructor(
    public payload: {
      ddqId: string;
      groupId: string;
    }
  ) {}
}

export class DDQReviewGroupGetSuccess implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_GET_GROUP_SUCCESS;
  constructor(public payload: { ddqReviewGroup: IDDQGroup }) {}
}

export class DDQReviewGroupGetError implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_GET_GROUP_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class DDQReviewSaveSection implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_SAVE_SECTION;
  constructor(
    public payload: {
      ddqId: string;
      section: IDDQSection;
      model: ISectionReviewsModel;
      withSubmit: boolean;
      withClarification: boolean;
      isLockDownPhaseStatus: boolean;
    }
  ) {}
}

export class DDQReviewSaveInternalSection implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_SAVE_INTERNAL_SECTION;
  constructor(
    public payload: {
      ddqId: string;
      sectionId: string;
      model: IDDQSection;
      withSubmit: boolean;
    }
  ) {}
}

export class DDQReviewSubmitSection implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_SUBMIT_SECTION;
  constructor(public payload: { ddqId: string; section: IDDQSection }) {}
}

export class DDQReviewSubmitInternalSection implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_SUBMIT_INTERNAL_SECTION;
  constructor(public payload: { ddqId: string; section: IDDQSection }) {}
}

export class DDQReviewUpdateFilterMessage implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_FILTER_MESSAGE;
  constructor(public payload: { filterMessage: boolean }) {}
}

export class DDQReviewUpdateSectionRating implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_SECTION_RATING;
  constructor(public payload: { rating: string; sectionId: string }) {}
}

export class DDQReviewUpdateGroupRating implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_GROUP_RATING;
  constructor(public payload: { rating: string; groupId: string; sectionId: string }) {}
}

export class DDQReviewUpdateQuestionRating implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_QUESTION_RATING;
  constructor(
    public payload: {
      rating: string;
      questionKey: string;
      groupId: string;
      sectionId: string;
      fromClarificationsDialog?: boolean;
    }
  ) {}
}

export class DDQReviewUpdateQuestionClarificationsAmount implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_QUESTION_CLARIFICATIONS_AMOUNT;
  constructor(
    public payload: {
      amount: number;
      questionKey: string;
      groupId: string;
      sectionId: string;
    }
  ) {}
}

export class DDQReviewUpdateOverallRating implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_OVERALL_RATING;
  constructor(public payload: { rating: string }) {}
}

export class DDQReviewUpdateResidualRiskCategory implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_RESIDUAL_RISK_CATEGORY;
  constructor(public payload: { riskCategory: ResidualRiskCategory }) {}
}

export class DDQReviewUpdateGroupComment implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_GROUP_COMMENT;
  constructor(public payload: { comment: string; sectionId: string; groupId: string }) {}
}

export class DDQReviewUpdateReviewerSectionComment implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_REVIEWER_SECTION_COMMENT;
  constructor(public payload: { comment: string; sectionId: string }) {}
}

export class DDQReviewUpdateOverallComment implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_OVERALL_COMMENT;
  constructor(public payload: { comment: string }) {}
}

export class DDQReviewUpdateExecutiveOverview implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_EXECUTIVE_OVERVIEW;
  constructor(public payload: { comment: string }) {}
}

export class DDQReviewSectionClearDirty implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_SECTION_CLEAR_DIRTY;
  constructor(public payload: { sectionId: string }) {}
}

export class DDQReviewSectionUpdateStatus implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_SECTION_UPDATE_STATUS;
  constructor(public payload: { sectionId: string; status: string }) {}
}

export class DDQReviewDocumentUpdateStatus implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_DOCUMENT_UPDATE_STATUS;
  constructor(public payload: { status: string }) {}
}

export class DDQReviewUpdateSectionApproverDocuments implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_SECTION_APPROVER_DOCUMENTS;
  constructor(
    public payload: {
      sectionId: string;
      answer?: any;
      deleteIndex?: number;
    }
  ) {}
}

export class DDQReviewUpdateSectionDocuments implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_SECTION_DOCUMENTS;
  constructor(
    public payload: {
      sectionId: string;
      answer?: any;
      deleteIndex?: number;
    }
  ) {}
}

export class DDQReviewUpdateGroupDocuments implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_GROUP_DOCUMENTS;
  constructor(
    public payload: {
      groupId: string;
      groupSectionId: string;
      answer?: any;
      deleteIndex?: number;
    }
  ) {}
}

export class DDQReviewUpdateQuestionAnswer implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_QUESTION_ANSWER;
  constructor(
    public payload: {
      sectionId: string;
      questionKey: string;
      answer: string;
      isChoiceAnswer?: boolean;
      isChoiceNarrative?: boolean;
    }
  ) {}
}

export class DDQReviewUpdateQuestionDocuments implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_QUESTION_DOCUMENTS;
  constructor(
    public payload: {
      sectionId: string;
      questionKey: string;
      answer?: any;
      deleteIndex?: number;
    }
  ) {}
}

export class DDQReviewApproveSection implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_APPROVE_SECTION;
  constructor(
    public payload: {
      ddqId: string;
      section: IDDQSection;
      model: IApproveModel;
      isInternalSection: boolean;
    }
  ) {}
}

export class DDQReviewApproveSectionSuccess implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_APPROVE_SECTION_SUCCESS;
  constructor() {}
}

export class DDQReviewRejectSection implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_REJECT_SECTION;
  constructor(
    public payload: {
      ddqId: string;
      section: IDDQSection;
      message: string;
      isInternalSection: boolean;
    }
  ) {}
}

export class DDQReviewRejectSectionSuccess implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_REJECT_SECTION_SUCCESS;
  constructor(public payload: { sectionId: string; message: string }) {}
}

export class DDQReviewApproveDocument implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_APPROVE_DOCUMENT;
  constructor(
    public payload: {
      ddqId: string;
      model: IApproveModel;
      updateHistoryActions: boolean;
      nextDDQCycleInviteYear?: number;
      reasoning?: string;
      distributorId?: string;
    }
  ) {}
}

export class DDQReviewSubmitAllSections implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_SUBMIT_ALL_SECTIONS;
  constructor(
    public payload: {
      ddqId: string;
      updateHistoryActions: boolean;
    }
  ) {}
}

export class DDQReviewSubmitAllSectionsError implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_SUBMIT_ALL_SECTIONS_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class DDQReviewSubmitAllSectionsSuccess implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_SUBMIT_ALL_SECTIONS_SUCCESS;
  constructor() {}
}

export class DDQReviewApproveAllSections implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_APPROVE_ALL_SECTIONS;
  constructor(
    public payload: {
      ddqId: string;
      updateHistoryActions: boolean;
    }
  ) {}
}

export class DDQReviewApproveAllSectionsError implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_APPROVE_ALL_SECTIONS_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class DDQReviewApproveAllSectionsSuccess implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_APPROVE_ALL_SECTIONS_SUCCESS;
  constructor() {}
}

export class DDQReviewSaveDocument implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_SAVE_DOCUMENT;
  constructor(
    public payload: {
      ddqId: string;
      model: IApproveModel;
      updateHistoryActions: boolean;
    }
  ) {}
}

export class DDQReviewSaveDocumentError implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_SAVE_DOCUMENT_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class DDQReviewSaveDocumentSuccess implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_SAVE_DOCUMENT_SUCCESS;
  constructor() {}
}

export class DDQReviewUpdateOverallDocuments implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_OVERALL_DOCUMENTS;
  constructor(
    public payload: {
      answer?: any;
      deleteIndex?: number;
    }
  ) {}
}

export class DDQReviewRecall implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_RECALL;
  constructor(public payload: { section: IDDQSection; ddqId: string; custom: boolean }) {}
}

export class DDQReviewRecallApprovedSection implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_RECALL_APPROVED_SECTION;
  constructor(
    public payload: {
      section: IDDQSection;
      ddqId: string;
      custom: boolean;
      isReviewer: boolean;
    }
  ) {}
}

export class DDQReviewExportPdfRetrieve implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_EXPORT_PDF;
  constructor(
    public payload?: {
      password: string;
      docName: string;
      distributorId: string;
      includeSummaryReport: boolean;
      includeExtraData: boolean;
      excludeComments: boolean;
      documentId: string;
    }
  ) {}
}

export class DDQReviewExportPdfRetrieveSuccess implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_EXPORT_PDF_SUCCESS;
  constructor(public payload: { pdf: any }) {}
}

export class DDQReviewExportPdfRetrieveError implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_EXPORT_PDF_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class DDQReviewGetHistoryActions implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_GET_HISTORY_ACTIONS;
  public constructor(
    public payload: {
      section?: IDDQSection;
      questionnaireId: string;
    }
  ) {}
}

export class DDQReviewUpdateHistoryActions implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_HISTORY_ACTIONS;
  constructor(public payload: { section: IDDQSection; actions: IAuditTrail[] }) {}
}

export class DDQUpdateSumQuestionsRating implements Action {
  readonly type = FMReviewActionTypes.DDQ_UPDATE_SUM_QUESTIONS_RATINGS;
  constructor(
    public payload: {
      ratingType: string;
      addAction: boolean;
      groupId: string;
      sectionId: string;
    }
  ) {}
}

export class DashboardActionsArchive implements Action {
  readonly type = FMReviewActionTypes.DASHBOARD_ACTIONS_ARCHIVE;
  constructor(
    public payload: {
      questionnaireId: string;
    }
  ) {}
}

export class DashboardActionsArchiveError implements Action {
  readonly type = FMReviewActionTypes.DASHBOARD_ACTIONS_ARCHIVE_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class DashboardActionsArchiveSuccess implements Action {
  readonly type = FMReviewActionTypes.DASHBOARD_ACTIONS_ARCHIVE_SUCCESS;
  constructor() {}
}

export class DDQReviewUpdateHasDifferentlyRatingProperty implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_HAS_DIFFERENTLY_RATING_PROPERTY;
  constructor(
    public payload: {
      result: IDiffRatingSection;
    }
  ) {}
}

export class DDQOverallReviewGetHistoryActions implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_OVERALL_GET_HISTORY_ACTIONS;
  public constructor(
    public payload: {
      ddqDocumentId?: string;
    }
  ) {}
}

export class DDQOverallReviewUpdateHistoryActions implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_OVERALL_UPDATE_HISTORY_ACTIONS;
  constructor(public payload: { actions: IAuditTrail[] }) {}
}

export class DDQReviewUpdateSectionClarificationsInDraftAmount implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_UPDATE_SECTION_CLARIFICATIONS_IN_DRAFT_AMOUNT;
  constructor(
    public payload: {
      amount: number;
      sectionId: string;
      groupId: string;
      questionKey: string;
    }
  ) {}
}

export class ActionSetAutosaveSectionData implements Action {
  readonly type = FMReviewActionTypes.SET_AUTOSAVE_SECTION_DATA;
  public constructor(
    public payload: {
      sectionId?: string,
      groupId?: string,
    }
  ) {}
}

export class ActionSendAutosaveSectionData implements Action {
  readonly type = FMReviewActionTypes.SEND_AUTOSAVE_SECTION_DATA;
  public constructor(
    public payload: {
      autosaveData: IAutosaveSectionData;
    }
  ) {}
}

export class ActionSetAutosaveInternalSectionData implements Action {
  readonly type = FMReviewActionTypes.SET_AUTOSAVE_INTERNAL_SECTION_DATA;
  public constructor(
    public payload: {
      sectionId?: string,
      groupId?: string,
      questionKey?: string 
    }
  ) {}
}

export class ActionSendAutosaveInternalSectionData implements Action {
  readonly type = FMReviewActionTypes.SEND_AUTOSAVE_INTERNAL_SECTION_DATA;
  public constructor(
    public payload: {
      autosaveData: IAutosaveInternalSectionData;
    }
  ) {}
}

export class ActionSetAutosaveApprovalData implements Action {
  readonly type = FMReviewActionTypes.SET_AUTOSAVE_APPROVAL_DATA;
  public constructor(
    public payload: {}
  ) {}
}

export class ActionSendAutosaveApprovalData implements Action {
  readonly type = FMReviewActionTypes.SEND_AUTOSAVE_APPROVAL_DATA;
  public constructor(
    public payload: {
      autosaveData: IAutosaveApprovalData;
    }
  ) {}
}

export class ActionValidateSection implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_VALIDATE_SECTION;
  public constructor(
    public payload: {
      sectionId?: string
    }
  ) {}
}

export class DDQReviewSectionUpdateSubmitProcessStatus implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_SECTION_UPDATE_SUBMIT_PROCESS_STATUS;
  constructor(public payload: { sectionId: string, status: boolean }) {}
}

export class DDQReviewGetOverallRiskPriority implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_GET_OVERALL_RISK_PRIORITY;
  constructor(public payload: { ddqDocumentId: string; }) {}
}

export class DDQReviewGetOverallRiskPrioritySuccess implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_GET_OVERALL_RISK_PRIORITY_SUCCESS;
  constructor(public payload: { overallRiskPriority: string; calculatedReviewFrequency: number }) {}
}

export class DDQReviewGetOverallRiskPriorityError implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_GET_OVERALL_RISK_PRIORITY_ERROR;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class DDQReviewGetRiskModelData implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_GET_RISK_MODEL_DATA;
  constructor(public payload: { ddqDocumentId: string; }) {}
}

export class DDQReviewGetRiskModelDataSuccess implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_GET_RISK_MODEL_DATA_SUCCESS;
  constructor(public payload: { riskModelData: IRiskModelData; }) {}
}

export class DDQReviewSetScheduleReview implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_SET_SCHEDULE_REVIEW;
  constructor(
    public payload: {
      distributorId: string;
      nextDDQCycleInviteYear?: number;
      reasoning?: string;
    }
  ) {}
}

export class DDQReviewAmendRiskModelData implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_AMEND_RISK_MODEL_DATA;
  constructor(public payload: { ddqDocumentId: string; ratingsModel: IAmendRatingsModel }) {}
}

export class DDQReviewAmendRiskModelDataSuccess implements Action {
  readonly type = FMReviewActionTypes.DDQ_REVIEW_AMEND_RISK_MODEL_DATA_SUCCESS;
  constructor(public payload: { riskModelData: IRiskModelData; }) {}
}

/**
 * FM review actions.
 */
export type FMReviewActions =
  | DDQReviewDataGet
  | DDQReviewDataGetSuccess
  | DDQReviewDataGetError
  | DDQReviewGroupGet
  | DDQReviewGroupGetSuccess
  | DDQReviewGroupGetError
  | DDQReviewSaveSection
  | DDQReviewSaveInternalSection
  | DDQReviewSubmitSection
  | DDQReviewSubmitInternalSection
  | DDQReviewUpdateFilterMessage
  | DDQReviewUpdateSectionRating
  | DDQReviewUpdateGroupRating
  | DDQReviewUpdateQuestionRating
  | DDQReviewUpdateQuestionClarificationsAmount
  | DDQReviewUpdateOverallRating
  | DDQReviewUpdateExecutiveOverview
  | DDQReviewUpdateResidualRiskCategory
  | DDQReviewUpdateGroupComment
  | DDQReviewUpdateReviewerSectionComment
  | DDQReviewSectionClearDirty
  | DDQReviewSectionUpdateStatus
  | DDQReviewDocumentUpdateStatus
  | DDQReviewUpdateSectionApproverDocuments
  | DDQReviewUpdateSectionDocuments
  | DDQReviewUpdateGroupDocuments
  | DDQReviewUpdateQuestionDocuments
  | DDQReviewUpdateQuestionAnswer
  | DDQReviewApproveSection
  | DDQReviewApproveSectionSuccess
  | DDQReviewRejectSection
  | DDQReviewRejectSectionSuccess
  | DDQReviewApproveDocument
  | DDQReviewSaveDocument
  | DDQReviewSaveDocumentSuccess
  | DDQReviewSaveDocumentError
  | DDQReviewSubmitAllSections
  | DDQReviewSubmitAllSectionsSuccess
  | DDQReviewSubmitAllSectionsError
  | DDQReviewUpdateOverallComment
  | DDQReviewUpdateOverallDocuments
  | DDQReviewRecall
  | DDQReviewRecallApprovedSection
  | DDQReviewExportPdfRetrieve
  | DDQReviewExportPdfRetrieveSuccess
  | DDQReviewExportPdfRetrieveError
  | DDQReviewGetHistoryActions
  | DDQReviewUpdateHistoryActions
  | DDQUpdateSumQuestionsRating
  | DashboardActionsArchive
  | DashboardActionsArchiveSuccess
  | DashboardActionsArchiveError
  | DDQReviewUpdateHasDifferentlyRatingProperty
  | DDQOverallReviewGetHistoryActions
  | DDQOverallReviewUpdateHistoryActions
  | DDQReviewUpdateSectionClarificationsInDraftAmount
  | ActionSetAutosaveSectionData
  | ActionSendAutosaveSectionData
  | ActionSetAutosaveInternalSectionData
  | ActionSendAutosaveInternalSectionData
  | ActionSetAutosaveApprovalData
  | ActionSendAutosaveApprovalData
  | ActionValidateSection
  | DDQReviewSectionUpdateSubmitProcessStatus
  | DDQReviewGetOverallRiskPriority
  | DDQReviewGetOverallRiskPrioritySuccess
  | DDQReviewGetOverallRiskPriorityError
  | DDQReviewGetRiskModelData
  | DDQReviewGetRiskModelDataSuccess
  | DDQReviewAmendRiskModelData
  | DDQReviewAmendRiskModelDataSuccess
  | DDQReviewSetScheduleReview;

/**
 * FM review initial state
 */
export const initialState: IFMReviewState = {
  ddqReviewData: null,
  loading: true,
  filterMessage: false,
  autosaveSectionData: null,
  autosaveApprovalData: null,
  autosaveInternalSectionData: null,
  riskModelData: null
};

const featureReviewFMSelector = createFeatureSelector<any>("reviewFMState");
export const selectorReviewFMState = createSelector(featureReviewFMSelector, (state) => {
  if (state) {
    return state.reviewFM;
  }
});

export const selectorLoading = createSelector(selectorReviewFMState, (state) => state && state.loading);

export const selectorDDQReviewData = createSelector(selectorReviewFMState, (state) => {
  if (state) {
    return state.ddqReviewData;
  }
});

export const selectorDDQReviewPDF = createSelector(selectorReviewFMState, (state) => {
  if (state) {
    return state.pdf;
  }
});

export const selectorHasOverallUpdate = createSelector(
  selectorReviewFMState,
  (state) => state && state.hasOverallUpdate
);

export const selectorSectionAutosaveData = createSelector(selectorReviewFMState, (state) => {
  if (state) {
    return state.autosaveSectionData;
  }
});

export const selectorInternalSectionAutosaveData = createSelector(selectorReviewFMState, (state) => {
  if (state) {
    return state.autosaveInternalSectionData;
  }
});

export const selectorApprovalAutosaveData = createSelector(selectorReviewFMState, (state) => {
  if (state) {
    return state.autosaveApprovalData;
  }
});

export const selectorRiskModelData = createSelector(selectorReviewFMState, (state) => {
  if (state) {
    return state.riskModelData;
  }
});

/**
 * Reducer function for fm review state.
 */
export function fmReviewReducer(state: IFMReviewState = initialState, action: FMReviewActions): IFMReviewState {
  switch (action.type) {
    case FMReviewActionTypes.DDQ_GET_REVIEW_DATA:
      return {
        ...state,
        loading: true,
        ddqReviewData: null,
        error: null
      };
    case FMReviewActionTypes.DDQ_GET_REVIEW_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...action.payload.ddqReviewData,
          showCycleSelector: action.payload.documentId ? true : false,
          approveOverallRating:
            action.payload.ddqReviewData && action.payload.ddqReviewData.approval.length !== 0
              ? action.payload.ddqReviewData.approval[0].rating
              : "None",
          approveOverallDocuments:
            action.payload.ddqReviewData && action.payload.ddqReviewData.approval.length !== 0
              ? action.payload.ddqReviewData.approval[0].approveDocuments
              : [],
          approveOverallComment:
            action.payload.ddqReviewData && action.payload.ddqReviewData.approval.length !== 0
              ? action.payload.ddqReviewData.approval[0].approverComment
              : "",
          auditorApproveOverallRating:
            action.payload.ddqReviewData &&
            action.payload.ddqReviewData.auditorApproval &&
            action.payload.ddqReviewData.auditorApproval.length !== 0
              ? action.payload.ddqReviewData.auditorApproval[0].rating
              : "None",
          auditorApproveOverallDocuments:
            action.payload.ddqReviewData &&
            action.payload.ddqReviewData.auditorApproval &&
            action.payload.ddqReviewData.auditorApproval.length !== 0
              ? action.payload.ddqReviewData.auditorApproval[0].approveDocuments
              : [],
          auditorApproveOverallComment:
            action.payload.ddqReviewData &&
            action.payload.ddqReviewData.auditorApproval &&
            action.payload.ddqReviewData.auditorApproval.length !== 0
              ? action.payload.ddqReviewData.auditorApproval[0].approverComment
              : "",
          sections: action.payload.ddqReviewData.sections.map((section: IDDQSection) => {
            const auditorSectionRating =
              !section.auditorReviews || (section.auditorReviews && section.auditorReviews.length === 0)
                ? "None"
                : section.auditorReviews[0].rating;
            const auditorSectionApprove =
              section.auditorReviews &&
              section.auditorReviews.length !== 0 &&
              section.auditorReviews[0].approvals.length !== 0
                ? section.auditorReviews[0].approvals[0]
                : false;
            const sectionRating = section.reviews.length === 0 ? "None" : section.reviews[0].rating;
            const sectionApprove =
              section.reviews.length !== 0 && section.reviews[0].approvals.length !== 0
                ? section.reviews[0].approvals[0]
                : false;
            const sumQuestionRatingsSection =
              section.reviews.length === 0
                ? {
                    none: 0,
                    query: 0,
                    satisfactory: 0,
                    unsatisfactory: 0
                  }
                : section.reviews[0].questionRatings;
            if (section.isCustom) {
              return {
                ...section,
                status: section.reviews.length === 0 ? "Draft" : section.reviews[0].status,
                reviewRating:
                  sectionApprove && sectionApprove.rating !== "None" ? sectionApprove.rating : sectionRating,
                reviewDocuments: section.reviews.length === 0 ? [] : section.reviews[0].reviewDocuments,
                approveDocuments: sectionApprove ? sectionApprove.approveDocuments : [],
                reviewComment: section.reviews.length === 0 ? "" : section.reviews[0].reviewerComment,
                sumQuestionRatings: sumQuestionRatingsSection,
                auditorReviewRating:
                  auditorSectionApprove && auditorSectionApprove.rating !== "None"
                    ? auditorSectionApprove.rating
                    : auditorSectionRating,
                auditorReviewDocuments:
                  !section.auditorReviews || (section.auditorReviews && section.auditorReviews.length === 0)
                    ? []
                    : section.auditorReviews[0].reviewDocuments,
                auditorReviewComment:
                  !section.auditorReviews || (section.auditorReviews && section.auditorReviews.length === 0)
                    ? ""
                    : section.auditorReviews[0].reviewerComment
              };
            }
            return {
              ...section,
              status: section.reviews.length === 0 ? section.status : section.reviews[0].status,
              reviewRating: sectionApprove && sectionApprove.rating !== "None" ? sectionApprove.rating : sectionRating,
              reviewDocuments: section.reviews.length === 0 ? [] : section.reviews[0].reviewDocuments,
              approveDocuments: sectionApprove ? sectionApprove.approveDocuments : [],
              reviewComment: section.reviews.length === 0 ? "" : section.reviews[0].reviewerComment,
              sumQuestionRatings: sumQuestionRatingsSection,
              auditorReviewRating:
                auditorSectionApprove && auditorSectionApprove.rating !== "None"
                  ? auditorSectionApprove.rating
                  : auditorSectionRating,
              auditorReviewDocuments:
                !section.auditorReviews || (section.auditorReviews && section.auditorReviews.length === 0)
                  ? []
                  : section.auditorReviews[0].reviewDocuments,
              auditorReviewComment:
                !section.auditorReviews || (section.auditorReviews && section.auditorReviews.length === 0)
                  ? ""
                  : section.auditorReviews[0].reviewerComment,
              groups: section.groups.map((group: IDDQGroup) => {
                const sumQuestionRatingsGroup = {
                  none: 0,
                  query: 0,
                  satisfactory: 0,
                  unsatisfactory: 0
                };
                let currentGroupReview;
                let currentAuditorGroupReview;
                if (section.reviews.length !== 0 && section.reviews[0].groupReviews.length !== 0) {
                  section.reviews[0].groupReviews.forEach((groupReview: IGroupReview) => {
                    if (group.id === groupReview.groupId) {
                      currentGroupReview = groupReview;
                    }
                  });
                }
                if (
                  (section.reviews.length !== 0 && section.reviews[0].groupReviews.length !== 0) ||
                  (section.auditorReviews &&
                    section.auditorReviews.length !== 0 &&
                    section.auditorReviews[0].groupReviews.length !== 0)
                ) {
                  let groupComment = "";
                  let groupRating = "None";
                  let groupDocuments = [];
                  let auditorGroupComment = "";
                  let auditorGroupRating = "None";
                  let auditorGroupDocuments = [];
                  let sumQuestionRatingsGroup = {
                    none: 0,
                    query: 0,
                    satisfactory: 0,
                    unsatisfactory: 0
                  };
                  if (section.reviews && section.reviews[0]) {
                    section.reviews[0].groupReviews.forEach((groupReview: IGroupReview) => {
                      if (group.id === groupReview.groupId) {
                        groupComment = groupReview.reviewerComment;
                        groupRating = groupReview.rating;
                        groupDocuments = groupReview.reviewDocuments;
                        sumQuestionRatingsGroup = groupReview.questionRatings;
                      }
                    });
                  }
                  if (section.auditorReviews && section.auditorReviews[0]) {
                    section.auditorReviews[0].groupReviews.forEach((groupReview: IGroupReview) => {
                      if (group.id === groupReview.groupId) {
                        auditorGroupComment = groupReview.reviewerComment;
                        auditorGroupRating = groupReview.rating;
                        auditorGroupDocuments = groupReview.reviewDocuments;
                        currentAuditorGroupReview = groupReview;
                      }
                    });
                  }
                  return {
                    ...group,
                    reviewDocuments: groupDocuments,
                    reviewRating: groupRating,
                    reviewComment: groupComment,
                    sumQuestionRatings: sumQuestionRatingsGroup,
                    auditorReviewDocuments: auditorGroupDocuments,
                    auditorReviewRating: auditorGroupRating,
                    auditorReviewComment: auditorGroupComment,
                    questions: group.questions.map((question: Question) => {
                      let questionRating = "None";
                      let auditorQuestionRating = "None";
                      if (
                        currentGroupReview &&
                        currentGroupReview.questionReviews &&
                        currentGroupReview.questionReviews.length !== 0
                      ) {
                        currentGroupReview.questionReviews.map((questionReview) => {
                          if (question.key === questionReview.questionKey) {
                            questionRating = questionReview.rating;
                          }
                        });
                      }
                      if (
                        currentAuditorGroupReview &&
                        currentAuditorGroupReview.questionReviews &&
                        currentAuditorGroupReview.questionReviews.length !== 0
                      ) {
                        currentAuditorGroupReview.questionReviews.map((questionReview) => {
                          if (question.key === questionReview.questionKey) {
                            auditorQuestionRating = questionReview.rating;
                          }
                        });
                      }
                      return {
                        ...question,
                        reviewRating: questionRating,
                        auditorReviewRating: auditorQuestionRating
                      };
                    }),
                  };
                } else {
                  return {
                    ...group,
                    questions: group.questions.map((question: Question) => {
                      return {
                        ...question,
                        reviewRating: "None"
                      };
                    }),
                    reviewDocuments: [],
                    reviewRating: "None",
                    reviewComment: "",
                    sumQuestionRatings: sumQuestionRatingsGroup
                  };
                }
              })
            };
          })
        },
        error: null
      };
    case FMReviewActionTypes.DDQ_GET_REVIEW_DATA_ERROR:
      return {
        ...state,
        loading: false,
        ddqReviewData: null,
        error: action.payload.error
      };
    case FMReviewActionTypes.DDQ_REVIEW_GET_GROUP:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FMReviewActionTypes.DDQ_REVIEW_GET_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          sections: state.ddqReviewData.sections.map((section) => {
            if (section.id !== action.payload.ddqReviewGroup.sectionId) {
              return section;
            }
            return {
              ...section,
              groups: section.groups.map((group) => {
                if (group.id !== action.payload.ddqReviewGroup.id) {
                  return group;
                }
                let currentGroupReview;
                let currentAuditorGroupReview;
                if (section.reviews.length !== 0 && section.reviews[0].groupReviews.length !== 0) {
                  section.reviews[0].groupReviews.forEach((groupReview: IGroupReview) => {
                    if (group.id === groupReview.groupId) {
                      currentGroupReview = groupReview;
                    }
                  });
                }
                if (
                  section.auditorReviews &&
                  section.auditorReviews.length !== 0 &&
                  section.auditorReviews[0].groupReviews.length !== 0
                ) {
                  section.auditorReviews[0].groupReviews.forEach((groupReview: IGroupReview) => {
                    if (group.id === groupReview.groupId) {
                      currentAuditorGroupReview = groupReview;
                    }
                  });
                }
                return {
                  ...group,
                  isQuestionsGet: true,
                  questions: action.payload.ddqReviewGroup.questions.map((question: Question) => {
                    let questionRating = "None";
                    let auditorQuestionRating = "None";
                    if (
                      currentGroupReview &&
                      currentGroupReview.questionReviews &&
                      currentGroupReview.questionReviews.length !== 0
                    ) {
                      currentGroupReview.questionReviews.map((questionReview) => {
                        if (question.key === questionReview.questionKey) {
                          questionRating = questionReview.rating;
                        }
                      });
                    }
                    if (
                      currentAuditorGroupReview &&
                      currentAuditorGroupReview.questionReviews &&
                      currentAuditorGroupReview.questionReviews.length !== 0
                    ) {
                      currentAuditorGroupReview.questionReviews.map((questionReview) => {
                        if (question.key === questionReview.questionKey) {
                          auditorQuestionRating = questionReview.rating;
                        }
                      });
                    }
                    return {
                      ...question,
                      reviewRating: questionRating,
                      auditorReviewRating: auditorQuestionRating
                    };
                  })
                };
              })
            };
          })
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_FILTER_MESSAGE:
      return {
        ...state,
        loading: false,
        filterMessage: action.payload.filterMessage
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_SECTION_RATING:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          sections: state.ddqReviewData.sections.map((section: IDDQSection) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }
            const sectionRating = action.payload.rating;
            return {
              ...section,
              sectionDirty: true,
              reviewRating: sectionRating !== section.reviewRating ? sectionRating : "None"
            };
          })
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_GROUP_RATING:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          sections: state.ddqReviewData.sections.map((section: IDDQSection) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }
            return {
              ...section,
              groupDirty: true,
              groups: section.groups.map((group: IDDQGroup) => {
                if (group.id !== action.payload.groupId) {
                  return group;
                }
                const groupRating = action.payload.rating;
                return {
                  ...group,
                  dirty: true,
                  reviewRating: groupRating !== group.reviewRating ? groupRating : "None"
                };
              })
            };
          })
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_QUESTION_RATING:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          sections: state.ddqReviewData.sections.map((section: IDDQSection) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }
            return {
              ...section,
              groupDirty: action.payload.fromClarificationsDialog ? section["groupDirty"] : true,
              groups: section.groups.map((group: IDDQGroup) => {
                if (group.id !== action.payload.groupId) {
                  return group;
                }
                return {
                  ...group,
                  dirty: action.payload.fromClarificationsDialog ? section["groupDirty"] : true,
                  questions: group.questions.map((question: Question) => {
                    if (question.key !== action.payload.questionKey) {
                      return question;
                    }
                    const questionRating = action.payload.rating;
                    return {
                      ...question,
                      reviewRating: questionRating !== question.reviewRating ? questionRating : "None"
                    };
                  })
                };
              })
            };
          })
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_QUESTION_CLARIFICATIONS_AMOUNT:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          sections: state.ddqReviewData.sections.map((section: IDDQSection) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }
            return {
              ...section,
              groups: section.groups.map((group: IDDQGroup) => {
                if (group.id !== action.payload.groupId) {
                  return group;
                }
                return {
                  ...group,
                  questions: group.questions.map((question: Question) => {
                    if (question.key !== action.payload.questionKey) {
                      return question;
                    }
                    return {
                      ...question,
                      openClarificationsAmount: action.payload.amount
                    };
                  })
                };
              })
            };
          })
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_OVERALL_RATING:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          approveOverallRating:
            action.payload.rating !== state.ddqReviewData.approveOverallRating ? action.payload.rating : "None",
          disableUpdate: false
        },
        hasOverallUpdate: true
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_RESIDUAL_RISK_CATEGORY:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          residualRiskCategory:
            action.payload.riskCategory !== state.ddqReviewData.residualRiskCategory
              ? action.payload.riskCategory
              : null,
          disableUpdate: false
        },
        hasOverallUpdate: true
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_GROUP_COMMENT:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: true,
          sections: state.ddqReviewData.sections.map((section: IDDQSection) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }
            return {
              ...section,
              groupDirty: true,
              groups: section.groups.map((group: IDDQGroup) => {
                if (group.id !== action.payload.groupId) {
                  return group;
                }
                return {
                  ...group,
                  dirty: true,
                  reviewComment: action.payload.comment
                };
              })
            };
          })
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_REVIEWER_SECTION_COMMENT:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: true,
          sections: state.ddqReviewData.sections.map((section: IDDQSection) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }
            return {
              ...section,
              sectionDirty: true,
              reviewComment: action.payload.comment
            };
          })
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_OVERALL_COMMENT:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: true,
          approveOverallComment: action.payload.comment
        },
        hasOverallUpdate: true
      };

    case FMReviewActionTypes.DDQ_REVIEW_EXECUTIVE_OVERVIEW:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: true,
          executiveOverview: action.payload.comment
        },
        hasOverallUpdate: true
      };
      

    case FMReviewActionTypes.DDQ_REVIEW_SECTION_CLEAR_DIRTY:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          sections: state.ddqReviewData.sections.map((section: IDDQSection) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }
            return {
              ...section,
              sectionDirty: false,
              groupDirty: false,
              groups: section.groups.map((group: IDDQGroup) => {
                return {
                  ...group,
                  dirty: false
                };
              })
            };
          })
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_SECTION_UPDATE_STATUS:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          sections: state.ddqReviewData.sections.map((section: IDDQSection) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }
            return {
              ...section,
              status: action.payload.status
            };
          })
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_DOCUMENT_UPDATE_STATUS:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          status: action.payload.status
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_SECTION_APPROVER_DOCUMENTS:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          sections: state.ddqReviewData.sections.map((section: IDDQSection) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }
            return {
              ...section,
              approveDocuments:
                action.payload.deleteIndex !== undefined
                  ? [...section.approveDocuments]
                      .slice(0, action.payload.deleteIndex)
                      .concat([...section.approveDocuments].slice(action.payload.deleteIndex + 1))
                  : Object.keys(action.payload.answer).length === 0 && action.payload.answer.constructor === Object
                  ? []
                  : section.approveDocuments.concat(action.payload.answer)
            };
          })
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_SECTION_DOCUMENTS:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          sections: state.ddqReviewData.sections.map((section: IDDQSection) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }
            return {
              ...section,
              sectionDirty: true,
              reviewDocuments:
                action.payload.deleteIndex !== undefined
                  ? [...section.reviewDocuments]
                      .slice(0, action.payload.deleteIndex)
                      .concat([...section.reviewDocuments].slice(action.payload.deleteIndex + 1))
                  : Object.keys(action.payload.answer).length === 0 && action.payload.answer.constructor === Object
                  ? []
                  : section.reviewDocuments.concat(action.payload.answer)
            };
          })
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_GROUP_DOCUMENTS:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          sections: state.ddqReviewData.sections.map((section: IDDQSection) => {
            if (section.id !== action.payload.groupSectionId) {
              return section;
            }
            return {
              ...section,
              groupDirty: true,
              groups: section.groups.map((group: IDDQGroup) => {
                if (group.id !== action.payload.groupId) {
                  return group;
                }
                return {
                  ...group,
                  dirty: true,
                  reviewDocuments:
                    action.payload.deleteIndex !== undefined
                      ? [...group.reviewDocuments]
                          .slice(0, action.payload.deleteIndex)
                          .concat([...group.reviewDocuments].slice(action.payload.deleteIndex + 1))
                      : Object.keys(action.payload.answer).length === 0 && action.payload.answer.constructor === Object
                      ? []
                      : group.reviewDocuments.concat(action.payload.answer)
                };
              })
            };
          })
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_QUESTION_DOCUMENTS:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          sections: state.ddqReviewData.sections.map((section: IDDQSection) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }
            return {
              ...section,
              sectionDirty: true,
              groups: section.groups.map((group: IDDQGroup) => {
                return {
                  ...group,
                  questions: group.questions.map((question: Question) => {
                    if (question.key !== action.payload.questionKey) {
                      return question;
                    }
                    return {
                      ...question,
                      answer: {
                        ...question.answer,
                        documents:
                          action.payload.deleteIndex !== undefined
                            ? [...question.answer.documents]
                                .slice(0, action.payload.deleteIndex)
                                .concat([...question.answer.documents].slice(action.payload.deleteIndex + 1))
                            : Object.keys(action.payload.answer).length === 0 &&
                              action.payload.answer.constructor === Object
                            ? []
                            : question.answer.documents.concat(action.payload.answer)
                      }
                    };
                  })
                };
              })
            };
          })
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_OVERALL_DOCUMENTS:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          approveOverallDocuments:
            action.payload.deleteIndex !== undefined
              ? [...state.ddqReviewData.approveOverallDocuments]
                .slice(0, action.payload.deleteIndex)
                .concat([...state.ddqReviewData.approveOverallDocuments].slice(action.payload.deleteIndex + 1))
              : Object.keys(action.payload.answer).length === 0 && action.payload.answer.constructor === Object
                ? []
                : state.ddqReviewData.approveOverallDocuments.concat(action.payload.answer)
        },
        hasOverallUpdate: true
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_QUESTION_ANSWER:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: true,
          sections: state.ddqReviewData.sections.map((section: IDDQSection) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }
            return {
              ...section,
              sectionDirty: true,
              groups: section.groups.map((group: IDDQGroup) => {
                return {
                  ...group,
                  questions: group.questions.map((question: Question) => {
                    if (question.key !== action.payload.questionKey) {
                      return question;
                    }
                    if (action.payload.isChoiceAnswer) {
                      if (action.payload.isChoiceNarrative) {
                        return {
                          ...question,
                          answer: Object.assign({}, question.answer, {
                            narrative: action.payload.answer
                          })
                        };
                      } else {
                        return {
                          ...question,
                          answer: Object.assign({}, question.answer, {
                            answers: action.payload.answer,
                            empty: false
                          })
                        };
                      }
                    } else {
                      return {
                        ...question,
                        answer: {
                          ...question.answer,
                          answer: action.payload.answer,
                          empty: action.payload.answer === ""
                        }
                      };
                    }
                  })
                };
              })
            };
          })
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_EXPORT_PDF:
      return {
        ...state,
        loading: true,
        pdf: null,
        error: null
      };

    case FMReviewActionTypes.DDQ_REVIEW_EXPORT_PDF_SUCCESS:
      return {
        ...state,
        loading: false,
        pdf: action.payload.pdf,
        error: null
      };

    case FMReviewActionTypes.DDQ_REVIEW_EXPORT_PDF_ERROR:
      return {
        ...state,
        loading: false,
        pdf: null,
        error: action.payload.error
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_HISTORY_ACTIONS:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          sections: state.ddqReviewData.sections.map((section) => {
            if (section.id !== action.payload.section.id) {
              return section;
            }
            return {
              ...section,
              historyActions: action.payload.actions
            };
          })
        }
      };

    case FMReviewActionTypes.DDQ_UPDATE_SUM_QUESTIONS_RATINGS:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          sections: state.ddqReviewData.sections.map((section: IDDQSection) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }
            return {
              ...section,
              sumQuestionRatings: {
                ...section.sumQuestionRatings,
                query:
                  action.payload.ratingType === "Query"
                    ? action.payload.addAction
                      ? section.sumQuestionRatings.query + 1
                      : section.sumQuestionRatings.query - 1
                    : section.sumQuestionRatings.query,
                satisfactory:
                  action.payload.ratingType === "Satisfactory"
                    ? action.payload.addAction
                      ? section.sumQuestionRatings.satisfactory + 1
                      : section.sumQuestionRatings.satisfactory - 1
                    : section.sumQuestionRatings.satisfactory,
                unsatisfactory:
                  action.payload.ratingType === "Unsatisfactory"
                    ? action.payload.addAction
                      ? section.sumQuestionRatings.unsatisfactory + 1
                      : section.sumQuestionRatings.unsatisfactory - 1
                    : section.sumQuestionRatings.unsatisfactory
              },
              groups: section.groups.map((group: IDDQGroup) => {
                if (group.id !== action.payload.groupId) {
                  return group;
                }
                return {
                  ...group,
                  sumQuestionRatings: {
                    ...section.sumQuestionRatings,
                    query:
                      action.payload.ratingType === "Query"
                        ? action.payload.addAction
                          ? group.sumQuestionRatings.query + 1
                          : group.sumQuestionRatings.query - 1
                        : group.sumQuestionRatings.query,
                    satisfactory:
                      action.payload.ratingType === "Satisfactory"
                        ? action.payload.addAction
                          ? group.sumQuestionRatings.satisfactory + 1
                          : group.sumQuestionRatings.satisfactory - 1
                        : group.sumQuestionRatings.satisfactory,
                    unsatisfactory:
                      action.payload.ratingType === "Unsatisfactory"
                        ? action.payload.addAction
                          ? group.sumQuestionRatings.unsatisfactory + 1
                          : group.sumQuestionRatings.unsatisfactory - 1
                        : group.sumQuestionRatings.unsatisfactory
                  }
                };
              })
            };
          })
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_SAVE_DOCUMENT:
      return {
        ...state,
        loading: true
      };

    case FMReviewActionTypes.DDQ_REVIEW_SAVE_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        hasOverallUpdate: false
      };

    case FMReviewActionTypes.DDQ_REVIEW_SAVE_DOCUMENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case FMReviewActionTypes.DDQ_REVIEW_REJECT_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          sections: state.ddqReviewData.sections.map((section: IDDQSection) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }
            return {
              ...section,
              previousRejectionReason: action.payload.message
            };
          })
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_HAS_DIFFERENTLY_RATING_PROPERTY:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          sections: state.ddqReviewData.sections.map((section: IDDQSection) => {
            if (section.id !== action.payload.result.sectionId) {
              return section;
            }
            return {
              ...section,
              hasRatingUpdated: action.payload.result.hasRatingUpdated,
              groups: section.groups.map((group: IDDQGroup) => {
                let groupFiltered: IDiffRatingGroup;
                let hasRatingUpdatedGroup = group.hasRatingUpdated;
                groups: for (let i = 0; i < section.groups.length; i++) {
                  if (action.payload.result.groups[i].id === group.id) {
                    groupFiltered = action.payload.result.groups[i];
                    hasRatingUpdatedGroup = groupFiltered.hasRatingUpdated;
                    break groups;
                  }
                }
                return {
                  ...group,
                  hasRatingUpdated: hasRatingUpdatedGroup,
                  questions: group.questions.map((question: Question) => {
                    let hasRatingUpdatedQuestion = question.hasRatingUpdated;
                    questions: for (let i = 0; i < groupFiltered.questions.length; i++) {
                      if (groupFiltered.questions[i].key === question.key) {
                        hasRatingUpdatedQuestion = groupFiltered.questions[i].hasRatingUpdated;
                        break questions;
                      }
                    }
                    return {
                      ...question,
                      hasRatingUpdated: hasRatingUpdatedQuestion
                    };
                  })
                };
              })
            };
          })
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_OVERALL_GET_HISTORY_ACTIONS:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          historyActions: state.ddqReviewData.historyActions ? state.ddqReviewData.historyActions : null
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_OVERALL_UPDATE_HISTORY_ACTIONS:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          historyActions: action.payload.actions
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_UPDATE_SECTION_CLARIFICATIONS_IN_DRAFT_AMOUNT:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          sections: state.ddqReviewData.sections.map((section: IDDQSection) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }
            var clarificationsAmountInDraft =
              action.payload.amount === 0
                ? action.payload.amount
                : section.clarificationsAmountInDraft
                  ? section.clarificationsAmountInDraft + action.payload.amount
                  : action.payload.amount;
            return {
              ...section,
              clarificationsAmountInDraft: clarificationsAmountInDraft,
              isContainsClarifications: clarificationsAmountInDraft === 0 ? false : true,
              groups: section.groups.map((group) => {
                if (group.id !== action.payload.groupId) {
                  return group
                }
                return {
                  ...group,
                  isContainsClarifications: clarificationsAmountInDraft === 0 ? false : true,
                  questions: group.questions.map((question => {
                    if (question.key !== action.payload.questionKey) {
                      return question
                    }
                    return {
                      ...question,
                      isContainsClarifications: clarificationsAmountInDraft === 0 ? false : true
                    }
                  }))
                }
              })
            };
          })
        }
      };

    case FMReviewActionTypes.SET_AUTOSAVE_SECTION_DATA:
      let sectionReview: ISectionReviewsModel;
      let section: IDDQSection;
      for (let i in state.ddqReviewData.sections) {
        section = state.ddqReviewData.sections[i];
        if (action.payload.sectionId && section.id === action.payload.sectionId) {
          sectionReview = {
            comment: section.reviewComment,
            rating: section.reviewRating,
            reviewDocuments: section.reviewDocuments,
            groupReviews: []
          }

          break;
        } else if (action.payload.groupId) {
          for (let j in section.groups) {
            if (action.payload.groupId === section.groups[j].id) {
              sectionReview = {
                comment: section.reviewComment,
                rating: section.reviewRating,
                reviewDocuments: section.reviewDocuments,
                groupReviews: []
              }

              break;
            }
          }
        }

        if (sectionReview) {
          break;
        }
      }

      if (sectionReview) {
        _(section.groups).each((group: IDDQGroup) => {
          let groupReview = {
            groupId: group ? group.id : null,
            comment: group.reviewComment,
            rating: group.reviewRating,
            reviewDocuments: group.reviewDocuments,
            questionReviews: [],
            isGroupExpanded: group.questions && group.questions.length !== 0
          };

          if (groupReview.isGroupExpanded) {
            _(group.questions).each((question: Question) => {
              groupReview.questionReviews.push({
                questionKey: question.key,
                rating: question.reviewRating
              });
            });
          }

          sectionReview.groupReviews.push(groupReview);
        });

        return {
          ...state,
          autosaveSectionData: {
            documentId: state.ddqReviewData.id,
            sectionId: section.id,
            sectionReview
          }
        };
      } else {
        return {
          ...state
        }
      }      

    case FMReviewActionTypes.SEND_AUTOSAVE_SECTION_DATA:
      return {
        ...state,
        loading: false,
        autosaveSectionData: null
      };

    case FMReviewActionTypes.SET_AUTOSAVE_INTERNAL_SECTION_DATA:
      let internalSectionReview: IDDQSection;
      for (let i in state.ddqReviewData.sections) {
        let section = state.ddqReviewData.sections[i];
        if (action.payload.sectionId && section.id === action.payload.sectionId) {
          internalSectionReview = section;
          break;
        } else if (action.payload.groupId) {
          for (let j in section.groups) {
            if (action.payload.groupId === section.groups[j].id) {
              internalSectionReview = section;
              break;
            }
          }
        } else if (action.payload.questionKey) {
          for (let j in section.groups) {
            for (let k in section.groups[j].questions) {
              if (section.groups[j].questions[k].key === action.payload.questionKey) {
                internalSectionReview = section;
                break;
              }
            }
          }
        }

        if (internalSectionReview) {
          break;
        }
      }

      if (internalSectionReview) {
        return {
          ...state,
          autosaveInternalSectionData: {
            documentId: state.ddqReviewData.id,
            sectionId: internalSectionReview.id,
            sectionReview: internalSectionReview
          }
        };
      } else {
        return {
          ...state
        }
      }

    case FMReviewActionTypes.SEND_AUTOSAVE_INTERNAL_SECTION_DATA:
      return {
        ...state,
        loading: false,
        autosaveInternalSectionData: null
      };

    case FMReviewActionTypes.SET_AUTOSAVE_APPROVAL_DATA:
      return {
        ...state,
        autosaveApprovalData: {
          documentId: state.ddqReviewData.id,
          approval: {
            message: state.ddqReviewData.approveOverallComment,
            approveDocuments: state.ddqReviewData.approveOverallDocuments,
            rating: state.ddqReviewData.approveOverallRating,
            executiveOverview: state.ddqReviewData.executiveOverview
              ? state.ddqReviewData.executiveOverview
              : null,
            residualRiskCategory: state.ddqReviewData.residualRiskCategory
          }
        }
      }

    case FMReviewActionTypes.SEND_AUTOSAVE_SECTION_DATA:
      return {
        ...state,
        loading: false,
        autosaveApprovalData: null
      };

    case FMReviewActionTypes.DDQ_REVIEW_VALIDATE_SECTION:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          sections: state.ddqReviewData.sections.map((section: IDDQSection) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }
            let sectionHasEmptyRating = section.reviewRating === "None" || section.reviewRating === "";
            let sectionHasChildWithEmptyRating = false;
            let sectionGroupHasChildWithEmptyRating = false;
            return {
              ...section,
              groups: section.groups.map((group: IDDQGroup) => {
                let isGroupRatingEmpty = (group.reviewRating === "None" || group.reviewRating === "") && group.isRelevance === false;
                let groupHasChildWithEmptyRating = false;
                if (!sectionHasChildWithEmptyRating && isGroupRatingEmpty) {
                  sectionHasChildWithEmptyRating = true;
                }
                return {
                  ...group,
                  questions: group.questions.map((question: Question) => {
                    let isQuestionRatingEmpty = (question.reviewRating === "None" || question.reviewRating === "" || question.reviewRating === "Query") && question.isRelevance === false;
                    if (!groupHasChildWithEmptyRating && isQuestionRatingEmpty) {
                      groupHasChildWithEmptyRating = true;
                    }
                    if (!sectionGroupHasChildWithEmptyRating && groupHasChildWithEmptyRating) {
                      sectionGroupHasChildWithEmptyRating = true;
                    }
                    return {
                      ...question,
                      showValidationAlert: isQuestionRatingEmpty
                    };
                  }),
                  showValidationAlert: isGroupRatingEmpty,
                  showChildValidationAlert: groupHasChildWithEmptyRating
                };
              }),
              showValidationAlert: sectionHasEmptyRating,
              showChildValidationAlert: sectionHasChildWithEmptyRating || sectionGroupHasChildWithEmptyRating
            };
          })
        }
      };
  
    case FMReviewActionTypes.DDQ_REVIEW_SECTION_UPDATE_SUBMIT_PROCESS_STATUS:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          disableUpdate: false,
          sections: state.ddqReviewData.sections.map((section: IDDQSection) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }
            return {
              ...section,
              submitProcessStatus: action.payload.status
            };
          })
        }
      };

    case FMReviewActionTypes.DDQ_REVIEW_GET_OVERALL_RISK_PRIORITY:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FMReviewActionTypes.DDQ_REVIEW_GET_OVERALL_RISK_PRIORITY_SUCCESS:
      return {
        ...state,
        loading: false,
        ddqReviewData: {
          ...state.ddqReviewData,
          overallRiskPriority: action.payload.overallRiskPriority,
          calculatedReviewFrequency: action.payload.calculatedReviewFrequency
        }
      };
      
    case FMReviewActionTypes.DDQ_REVIEW_GET_OVERALL_RISK_PRIORITY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case FMReviewActionTypes.DDQ_REVIEW_GET_RISK_MODEL_DATA:
      return {
        ...state,
        riskModelData: null
      };

    case FMReviewActionTypes.DDQ_REVIEW_GET_RISK_MODEL_DATA_SUCCESS:
      return {
        ...state,
        riskModelData: action.payload.riskModelData
      };

    case FMReviewActionTypes.DDQ_REVIEW_AMEND_RISK_MODEL_DATA:
      return {
        ...state,
        riskModelData: null
      };
      
    case FMReviewActionTypes.DDQ_REVIEW_AMEND_RISK_MODEL_DATA_SUCCESS:
      return {
        ...state,
        riskModelData: action.payload.riskModelData,
        ddqReviewData: {
          ...state.ddqReviewData,
          overallRiskPriority: action.payload.riskModelData ? action.payload.riskModelData['overallRiskPriority'] : state.ddqReviewData.overallRiskPriority,
          calculatedReviewFrequency:  action.payload.riskModelData ? action.payload.riskModelData['calculatedReviewFrequency'] : state.ddqReviewData.calculatedReviewFrequency
        }
      };
    default:
      return state;
  }
}

/**
 * Interface for fm review state.
 */
export interface IFMReviewState {
  loading: boolean;
  ddqReviewData?: IDDQDocument;
  error?: HttpErrorResponse;
  filterMessage?: boolean;
  pdf?: any;
  hasOverallUpdate?: boolean;
  autosaveSectionData: IAutosaveSectionData;
  autosaveInternalSectionData: IAutosaveInternalSectionData;
  autosaveApprovalData: IAutosaveApprovalData;
  riskModelData?: IRiskModelData;
}

<div *ngIf="showDeploymentBanner" class="deployment-banner">
  <div class="text">
    <div class="ddd-icon amber-flag-primary"></div>
    <span>
      We are undergoing planned maintenance
      <b
        >in
        <countdown #countdown [config]="{ leftTime: timeToDeployment }"></countdown>
        minutes</b
      >. Please make sure that all ongoing work is saved prior to then.</span
    >
  </div>
</div>

<header *ngIf="loggedUser" class="c-header">
  <div class="c-header__wrapper">
    <div class="c-header__logo"></div>
    <div class="c-header__body">
      <div *ngIf="withMenu">
        <div class="inner">
          <nav role="navigation">
            <ul class="parent-list">
              <li class="parent-list-item" *ngIf="menuItems.dashboard">
                <a
                  *ngIf="menuItems.dashboard"
                  routerLink="/dashboard"
                  [routerLinkActive]="['active']"
                  class="nav-item"
                  >{{ "HEADER.DASHBOARD" | translate }}</a
                >
              </li>
              <li class="parent-list-item" *ngIf="menuItems.fmguest">
                <a routerLink="/fmguest" [routerLinkActive]="['active']" class="nav-item">{{
                  "HEADER.FMGUEST" | translate
                }}</a>
              </li>
              <li class="parent-list-item" *ngIf="menuItems.assign">
                <a routerLink="/qc/assign" [routerLinkActive]="['active']" class="nav-item">{{
                  "HEADER.ASSIGN" | translate
                }}</a>
              </li>
              <li class="parent-list-item" *ngIf="menuItems.completer">
                <a routerLink="/qc/complete" [routerLinkActive]="['active']" class="nav-item">{{
                  "HEADER.COMPLETE" | translate
                }}</a>
              </li>
              <li class="parent-list-item" *ngIf="menuItems.releaser">
                <a routerLink="/qc/release" [routerLinkActive]="['active']" class="nav-item">{{
                  "HEADER.RELEASE" | translate
                }}</a>
              </li>
              <li
                class="parent-list-item with-dropdown"
                *ngIf="menuItems.distributors || menuItems.analytics || menuItems.fmDistributors"
              >
                <div class="item-menu" [ngClass]="{ active: checkActive() }">
                  <span>{{ "HEADER.DISTRIBUTORS" | translate }}</span>
                </div>
                <ul class="dropdown">
                  <li *ngIf="menuItems.fmDistributors">
                    <a routerLink="/fm-distributors" [routerLinkActive]="['active']" class="nav-item sub-link">{{
                      "HEADER.DISTRIBUTORS_DATA_GRID" | translate
                    }}</a>
                  </li>
                  <li *ngIf="menuItems.analytics">
                    <a routerLink="/analytics" [routerLinkActive]="['active']" class="nav-item sub-link">{{
                      "HEADER.ANALYTICS" | translate
                    }}</a>
                  </li>
                </ul>
              </li>
              <li class="parent-list-item" *ngIf="menuItems.manageTemplate">
                <a routerLink="/managetemplate" [routerLinkActive]="['active']" class="nav-item">{{
                  "HEADER.TEMPLATE_MANAGER" | translate
                }}</a>
              </li>
              <li class="parent-list-item" *ngIf="menuItems.reviewGroups">
                <a routerLink="/reviewgroups" [routerLinkActive]="['active']" class="nav-item">{{
                  "HEADER.REVIEW_GROUPS" | translate
                }}</a>
              </li>
              <li class="parent-list-item" *ngIf="menuItems.fundManagers">
                <a routerLink="/fundmanagers" [routerLinkActive]="['active']" class="nav-item">{{
                  "HEADER.FUND_MANAGERS" | translate
                }}</a>
              </li>
              <li class="parent-list-item" *ngIf="menuItems.adminFMCompanies">
                <a routerLink="/support/fund-managers" [routerLinkActive]="['active']" class="nav-item">{{
                  "HEADER.FUND_MANAGERS" | translate
                }}</a>
              </li>
              <li class="parent-list-item" *ngIf="menuItems.adminDistributorCompanies">
                <a routerLink="/support/distributors" [routerLinkActive]="['active']" class="nav-item">{{
                  "HEADER.DISTRIBUTORS" | translate
                }}</a>
              </li>
              <li class="parent-list-item" *ngIf="menuItems.adminUsers">
                <a routerLink="/support/users" [routerLinkActive]="['active']" class="nav-item">{{
                  "HEADER.USERS" | translate
                }}</a>
              </li>
              <li class="parent-list-item" *ngIf="menuItems.allinteractions">
                <a routerLink="allinteractions" [routerLinkActive]="['active']" class="nav-item">{{
                  "HEADER.ALL_INTERRACTIONS" | translate
                }}</a>
              </li>
              <li class="parent-list-item" *ngIf="menuItems.alltasks">
                <a routerLink="/tasks" [routerLinkActive]="['active']" class="nav-item">{{
                  "HEADER.TASKS" | translate
                }}</a>
              </li>
              <li class="parent-list-item" *ngIf="menuItems.adminEmails">
                <a routerLink="/support/emails" [routerLinkActive]="['active']" class="nav-item"
                  >{{ "HEADER.EMAILS" | translate
                  }}{{
                    loggedUser.newEmailsCount && loggedUser.newEmailsCount !== 0
                      ? " (" + loggedUser.newEmailsCount + ")"
                      : ""
                  }}</a
                >
              </li>
              <li class="parent-list-item with-dropdown" *ngIf="menuItems.reporting || menuItems.fmanalytics">
                <div class="item-menu" [ngClass]="{ active: checkReportingActive() }">
                  <span>{{ "HEADER.REPORTING" | translate }}</span>
                </div>
                <ul class="dropdown">
                  <li *ngIf="menuItems.reporting">
                    <a routerLink="/reporting" [routerLinkActive]="['active']" class="nav-item sub-link">{{
                      "HEADER.REPORTS" | translate
                    }}</a>
                  </li>
                  <li *ngIf="menuItems.fmanalytics">
                    <a routerLink="/fmanalytics" [routerLinkActive]="['active']" class="nav-item sub-link">{{
                      "HEADER.ANALYTICS_FM" | translate
                    }}</a>
                  </li> 
                </ul>
              </li>
              <li class="parent-list-item" *ngIf="menuItems.fmtasks">
                <a routerLink="/fmtasks" [routerLinkActive]="['active']" class="nav-item">{{
                  "HEADER.TASKS" | translate
                }}</a>
              </li>
              <li class="parent-list-item" *ngIf="menuItems.documents">
                <a routerLink="/documents" [routerLinkActive]="['active']" class="nav-item">{{
                  "HEADER.DOCUMENTS" | translate
                }}</a>
              </li>
              <li class="parent-list-item" *ngIf="menuItems.admin">
                <a routerLink="/admin" [routerLinkActive]="['active']" class="nav-item">{{
                  "HEADER.ADMIN" | translate
                }}</a>
              </li>
              <li class="parent-list-item" *ngIf="menuItems.fmadmin">
                <a routerLink="/fmadmin" class="nav-item" [routerLinkActive]="['active']">{{
                  "HEADER.ADMIN" | translate
                }}</a>
              </li>
              <li class="parent-list-item" *ngIf="menuItems.help">
                <a routerLink="/help" [routerLinkActive]="['active']" class="nav-item">{{
                  "HEADER.HELP" | translate
                }}</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div class="c-header__user-wrapper c-font--caption">
      <div class="c-header__username" title="{{ loggedUser.firstName + ' ' + loggedUser.lastName }}">{{ loggedUser.firstName + " " + loggedUser.lastName }}</div>
      <div class="c-header__company" title="{{ userCurrentCompany && userCurrentCompany.companyName }}">{{ userCurrentCompany && userCurrentCompany.companyName }}</div>
    </div>
    <div>
      <div class="c-button--hex-container c-button--hex-dropdown">
        <input type="checkbox" class="c-button--hex" id="my-dropdown" [(ngModel)]="menuDropdown" name="my-checkbox" />
        <div class="c-button--hex-text c-font--heavy-bold" data-toggle="dropdown">
          {{ loggedUser.firstName[0] + loggedUser.lastName[0] }}
        </div>

        <ul>
          <li class="menu-item" (click)="goToProfile()">
            {{ "HEADER.PROFILE" | translate }}
          </li>
          <li *ngIf="!loggedUser.loginProviders" class="menu-item" (click)="changePassword()">
            {{ "HEADER.CHANGE_PASSWORD" | translate }}
          </li>
          <li class="menu-item" (click)="logout()">
            {{ "HEADER.LOGOUT" | translate }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>

<div
  class="c-sub-header change-distributor-dropdown"
  *ngIf="
    withCompanySelect &&
    loggedUser &&
    userCurrentCompany
  "
>
  <div class="c-sub-header__dropdown c-font--caption">
    You are acting on behalf of:&nbsp;
    <span class="c-font--bold"> {{ userCurrentCompany.companyName }} ({{ userCurrentCompany.lei }}) </span>
    <button class="c-button__dropdown c-font--caption c-color--accent c-font--bold" [matMenuTriggerFor]="selectTemplate">
      Change
    </button>
  </div>
  <mat-menu #selectTemplate="matMenu" class="right" xPosition="before">
    <button
      *ngFor="let company of loggedUser.companies; let i = index"
      mat-menu-item
      (click)="changeCompany(company.companyId)"
    >
      {{ company.companyName }} ({{company.lei}})
    </button>
  </mat-menu>
</div>

<div
  class="common-header change-distributor-dropdown support-select"
  *ngIf="(isAdmin && withMenu) || (loggedUser && loggedUser.isSessionOpened)"
>
  <ng-select
    *ngIf="!loggedUser.isSessionOpened"
    class="admin-company-list"
    [closeOnSelect]="true"
    [multiple]="false"
    [(ngModel)]="adminCurrentCompany"
    [items]="adminAllCompaniesList"
    (change)="adminCompanySelected()"
    bindLabel="fullname"
    bindValue="id"
    virtualScroll="true"
    notFoundText="Loading..."
    (search)="searchAdminCompanies($event)"
    (clear)="clearAdminSearch()"
    [searchFn]="customSearchFunction"
    (scrollToEnd)="fetchAdminCompanies()"
    placeholder="Click here to change Company"
  ></ng-select>

  <ng-select
    *ngIf="!loggedUser.isSessionOpened && adminCurrentCompany"
    class="admin-user-list"
    [closeOnSelect]="true"
    [multiple]="false"
    [(ngModel)]="adminCurrentUser"
    [items]="adminCompanyUsersList"
    (change)="startAdminSession()"
    bindLabel="fullname"
    bindValue="id"
    placeholder="Click here to select user"
  ></ng-select>
  <button class="ddd-button raised" (click)="endAdminSession()" *ngIf="loggedUser.isSessionOpened">End session</button>
</div>
